export default {
  data() {
    return {
      filter: {},
    }
  },

  created() {
    this.loadFiltersFromLocalStorage()
  },

  methods: {
    getFilterStorageKey() {
      return `filter-${this.$route.name}`.toLowerCase()
    },
    loadFiltersFromLocalStorage() {
      const loadFilters = localStorage.getItem(this.getFilterStorageKey())

      if (loadFilters) {
        this.filter = JSON.parse(loadFilters)
      }
    },
    saveFiltersToLocalStorage() {
      localStorage.setItem(
        this.getFilterStorageKey(),
        JSON.stringify(this.filter)
      )
    }
  },

  watch: {
    filter: {
      handler() {
        this.saveFiltersToLocalStorage()
      },
      deep: true
    },
  },
}
