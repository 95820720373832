<template>
  <div>
    <table-row :order="this.order" @expand="expanded = !expanded" />
    <template v-if="expanded">
      <table-row
        v-for="order in items.slice(1)"
        :key="order.id"
        :order="order"
        :items="items"
      />
    </template>
  </div>
</template>

<script>
import TableRow from '@/components/TableRow'
export default {
  name: 'TableRowGroup',
  components: { TableRow },
  data() {
    return {
      expanded: false,
      order: { ...this.items[0], length: this.items.length }
    }
  },
  props: ['items']
}
</script>

<style scoped></style>
